import React, { useMemo } from 'react';
import Slider from 'react-slick';

import { colors } from '../../styles/atoms/colors';

import Section from '../../molecules/Section/Section';

import HistoryCard from '../Cards/HistoryCard';

import {
  CardWrapper,
  Heading,
  HeadingWrapper,
  HistoryStyles,
  Kicker,
  Subhead,
  Wrapper,
} from './HistoryStyles';

import { getResponsive } from '../../utils/OptimizeResponsive';

import { NextArrow, PrevArrow } from './arrows';

const History = ({ component }) => {
  const mobileFlag = getResponsive();
  const { id, historyCards } = component;

  const settings = {
    dots: true,
    arrows: true,
    centerMode: false,
    draggable: true,
    useTransform: false,
    infinite: mobileFlag,
    adaptiveHeight: true,
    touchMove: true,
    swipe: true,
    swipeToSlide: true,
    initialSlide: 0,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerPadding: 60,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: 120,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          variableWidth: true,
          centerPadding: 0,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          centerMode: true,
          variableWidth: true,
          centerPadding: 0,
        },
      },
    ],
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };

  return (
    <Section id={id} bgColor={colors.base.white}>
      <HistoryStyles>
        <Wrapper>
          <HeadingWrapper>
            <Kicker>{component?.headingKicker}</Kicker>
            <Heading>{component?.heading}</Heading>
            <Subhead>{component?.subhead}</Subhead>
          </HeadingWrapper>
          <CardWrapper>
            <Slider {...settings}>
              {useMemo(
                () =>
                  historyCards.map(card => (
                    <HistoryCard
                      key={card?.id}
                      image={card?.image}
                      imageDescription={card?.imageDescription}
                      heading={card?.heading}
                      subhead={card?.subhead}
                      internalName={card?.internalName}
                    />
                  )),
                [historyCards]
              )}
            </Slider>
          </CardWrapper>
        </Wrapper>
      </HistoryStyles>
    </Section>
  );
};

export default History;
