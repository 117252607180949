import React from 'react';
import Icon from '../../styles/atoms/icons';

export const NextArrow = props => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    >
      <Icon
        id="carousel-right-arrow"
        style={{ width: '23px', height: '23px' }}
        isImage
      />
    </div>
  );
};

export const PrevArrow = props => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    >
      <Icon
        id="carousel-right-arrow"
        style={{ width: '23px', height: '23px' }}
        isImage
      />
    </div>
  );
};
