import styled from 'styled-components';
import { atMinWidth } from '../../styles/atoms/breakpoints';
import { colors } from '../../styles/atoms/colors';
import { font } from '../../styles/atoms/typography';

export const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 40px;
  text-align: center;
  width: 100%;

  ${atMinWidth.sm`
    gap: 48px;
  `}

  ${atMinWidth.md`
    gap: 88px;
  `}

  ${atMinWidth.sm`
    gap: 96px;
  `}
`;

export const HeadingWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 32px;
  text-align: center;
  max-width: 770px;
`;

export const Kicker = styled.p`
  ${font('overline', 'sm', '700')}
  color: ${colors.primary[500]};
`;

export const Heading = styled.h2`
  ${font('display', 'md', '700')}
  color: ${colors.gray[900]};

  ${atMinWidth.sm`
    ${font('display', 'lg', '700')}
  `}

  ${atMinWidth.md`
    ${font('display', 'xl', '700')}
  `}
`;

export const Subhead = styled.p`
  ${font('text', 'md', '400')}
  color: ${colors.gray[600]};

  ${atMinWidth.sm`
    ${font('text', 'lg', '400')}
  `}

  ${atMinWidth.md`
    ${font('text', 'xl', '400')}
  `}
`;

export const CardWrapper = styled.div`
  position: relative;
  max-width: 1060px;
  margin: 0px auto;
  width: 100%;

  .slick-arrow {
    &::before {
      position: absolute;
      visibility: hidden;
    }

    svg {
      position: absolute;
    }
  }

  .slick-prev {
    transform: rotateZ(180deg);

    svg {
      top: 0;
    }
  }

  .slick-next {
    transform: none;

    svg {
      bottom: 0;
    }
  }

  .slick-list {
    max-height: 350px;
  }

  ul.slick-dots {
    position: relative;
    bottom: 0;
    margin-top: 40px;

    li {
      button:before {
        font-size: 10px;
        color: ${colors.gray[200]};
        opacity: 1;
      }

      &.slick-active {
        button:before {
          font-size: 10px;
          color: ${colors.green[400]};
          opacity: 1;
        }
      }
    }
  }
`;

export const HistoryStyles = styled.div`
  .history-cards-wrapper {
    position: relative;
    max-width: 1060px;
    margin: 0 auto;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background: linear-gradient(90deg, #fff 0%, #ffffff00 8% 92%, #fff 100%);
      pointer-events: none;

      @media (max-width: 991px) {
        background: linear-gradient(
          90deg,
          #fff 0%,
          #ffffff00 0% 100%,
          #fff 100%
        );
      }

      @media (max-width: 767px) {
        background: linear-gradient(
          90deg,
          #fff 0%,
          #ffffff00 12% 75%,
          #fff 100%
        );
      }

      @media (max-width: 575px) {
        background: linear-gradient(
          90deg,
          #fff 0%,
          #ffffff00 16% 80%,
          #fff 100%
        );
      }

      @media (max-width: 420px) {
        background: linear-gradient(
          90deg,
          #fff 0%,
          #ffffff00 0% 100%,
          #fff 100%
        );
      }
    }

    .slick-list {
      max-height: 350px;
    }

    ul.slick-dots {
      @media (max-width: 767px) {
        position: relative;
        bottom: 0;
      }

      li {
        button:before {
          font-size: 10px;
          color: #d8dae6;
          opacity: 1;
        }

        &.slick-active {
          button:before {
            font-size: 10px;
            color: #01c9a1;
            opacity: 1;
          }
        }
      }
    }

    .history-card {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      background: #ffffff;
      box-shadow: 0px 4.672px 9.344px -2.336px rgb(19 48 205 / 10%),
        0px 2.336px 4.672px -2.336px rgb(19 48 205 / 6%);
      border-radius: 12px;
      max-width: 314px;
      height: 292px;
      max-height: 292px;
      padding: 28px;
      margin-left: 16px;
      margin-right: 16px;
      margin-bottom: 24px;

      .history-date {
        ${font('overline', 'md', '700')}
        color: ${colors.green[500]};
        margin-top: 8px;
      }

      .history-heading {
        ${font('text', 'lg', '700')}
        color: ${colors.gray[900]};
        margin-bottom: 9px;
      }

      .history-subhead p {
        ${font('text', 'md', '400')}
        color: ${colors.gray[900]};
      }
    }
  }
`;
